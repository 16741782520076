import React from 'react';
import { ErrorPage } from '@templates/ErrorPage';
import { NoResult } from '@olo-web/assets/images/NoResult.ui';
import { useMerchant } from '@olo-web/domain/merchants/queries/useMerchant';

export default function Custom404() {
  const { data: merchant } = useMerchant();
  console.log({ merchant });

  return (
    <ErrorPage
      header="404 Error: Page not found"
      img={<NoResult w={{ base: '240px', md: '366px' }} h={{ base: '198px', md: '301px' }} />}
    />
  );
}
